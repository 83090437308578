import { ExtendProps } from 'app';
import {
	useEffect,
	useRef,
	useState,
	Dispatch,
	SetStateAction,
	useCallback,
} from 'react';

import CloseIcon from '@mui/icons-material/Close';

type ModalProps = ExtendProps<{
	title?: string;
	src?: string;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}>;
export default function Modal(props: ModalProps) {
	const frameRef = useRef<HTMLIFrameElement>();
	const { isOpen, setIsOpen } = props;

	const dialogRef = useRef<HTMLDialogElement>(null);
	const backdropRef = useRef<HTMLDivElement>()
	// const dialogContentRef = useRef<HTMLDivElement>(null);

	
	useEffect(() => {
		dialogRef.current.addEventListener('keydown', (ev) => {
			if (ev.key === 'Escape') {
				setIsOpen(false);
			}
		});
	}, [setIsOpen]);

	// useEffect(() => {
	// 	backdropRef.current.addEventListener('click', ev => setIsOpen(false))
	// }, [])

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';

			if(!dialogRef.current.open) {
				dialogRef.current.showModal();
			}
		} else {
			document.body.style.overflow = 'unset';
			dialogRef.current.close();
		}

		// return () => {
		// 	if (dialogRef.current) document.body.style.overflow = 'hidden';
		// 	else document.body.style.overflow = 'unset';
		// };
	}, [isOpen]);

	// useEffect(() => {
	// 	dialogContentRef.current.addEventListener('focusout', () => {
	// 		document.body.style.overflow = 'unset';
	// 		dialogRef.current.close();
	// 		setIsOpen(false);
	// 	});
	// }, []);

	return (
		<div
			tabIndex={0}
			className={`modal-backdrop select-none w-screen h-screen fixed top-0 left-0 z-[3000] bg-[#00000060] ${
				!isOpen ? 'hidden z-[0]' : 'block z-[3000]'
			}`}
		>
			<div ref={backdropRef} className="modal-wrapper relative flex items-center justify-center h-full">
				<dialog
					className="relative w-[90vw] md:w-[70vw] lg:w-[60vw] h-[80vh] top-50 overflow-hidden"
					ref={dialogRef}
					// relative w-[60%] min-h-min h-[70%] flex flex-col justify-between rounded-2xl
				>
					{/* title */}
					<div className="relative flex items-center justify-between min-h-[5rem] ">
						<span className="font-bold">{props.title}</span>
						<button
							aria-label="Fechar caixa de diálogo"
							className="hover:bg-white hover:text-black flex items-center justify-center rounded-sm p-1"
							onClick={(_) => {
								document.body.style.overflow = 'unset'
								setIsOpen(false)
							}}
						>
							<CloseIcon className="text-xl" />
						</button>
					</div>

					{/* Content */}
					<div className="relative w-full h-full overflow-y-hidden">
						<iframe
							aria-label={`PDF com o conteúdo sobre ${props.title}`}
							ref={frameRef}
							id="pdf-frame"
							title={props.title}
							src={`${props.src}#toolbar=0&page=1&scrollbar=0&navpanes=0&embedded=true&statusbar=0&view=FitH;readonly=true;disableprint=true;`}
							style={{
								// pointerEvents: 'none',
								position: 'relative',
								width: '100%',
								height: '65vh',
							}}
						></iframe>
					</div>
				</dialog>
			</div>
		</div>
	);
}
