import { ExtendProps } from 'app';
import { HTMLAttributes } from 'react';

export default function NavigationNestedItem(
	props: ExtendProps<HTMLAttributes<HTMLLIElement>>
) {
	return (
		<li
			className={`w-full font-bold relative text-gray-50 uppercase after:content-[''] after:w-0 after:h-1 after:absolute after:-bottom-1 after:left-0 after:bg-gray-100 hover:after:w-full hover:after:transition-w hover:after:duration-200 text-sm`}
			{...props}
		/>
	);
}
