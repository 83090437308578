import { NavigationItemHoverContext } from '@/contexts/NavigationItemHoverContext';
import { ExtendProps } from 'app';
import { HTMLAttributes, MouseEvent, useContext, useRef } from 'react';
import { useAccessibilityStore } from '@/contexts/accessibility';

export default function NavigationItemLabel(
	props: ExtendProps<HTMLAttributes<HTMLElement>>
) {
	const labelRef = useRef<HTMLElement>(null);
	const { isHovered, setIsHovered } = useContext(NavigationItemHoverContext);
	const handleHover = (event: MouseEvent<HTMLElement>, value: boolean) => {
		setIsHovered(value);
	};

	return (
		<strong
			ref={labelRef}
			// onClick={ev => ev.preventDefault()}
			onMouseEnter={(ev) => handleHover(ev, true)}
			onMouseLeave={(ev) => handleHover(ev, false)}
			className={`outline-primary-dark flex items-center px-6 w-full h-full relative uppercase cursor-pointer hover:text-white hover:duration-200 text-sm ${
				isHovered ? 'bg-primary-dark text-white' : ''
			}`}
			{...props}
		/>
	);
}
