import NextLink, { LinkProps } from 'next/link';
import { CSSProperties, ReactNode } from 'react';

type NavigationSocialLink = {
 icon?: ReactNode,
 background: CSSProperties['background']
} & LinkProps

export default function NavigationSocialLink({
  href,
  icon,
  background,
  ...rest
}: NavigationSocialLink) {
	return (
		<NextLink
			target="_blank"
			href={href}
			className="p-2 text-white transform hover:scale-105 duration-200 ease-linear h-10 w-10 flex items-center justify-center"
			style={{ background: background ?? 'unset' }}
      {...rest}
		>
			{icon}
		</NextLink>
	);
}
