import { ExtendProps } from 'app';
import Footer from './Footer';
import Header from './Header';
import SocialLinks from '@/components/OffCanvas/SocialNavigation';
import Accessibility from '@/components/OffCanvas/Acessibility';
import Container from '@/components/Container';

import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Analytics, AnalyticsModel } from '@/lib/page-analytics';
import { useState } from 'react';
import { ModalBoletimInformativo } from '../modals/BoletimInformativo';

export default function PageLayout(props: ExtendProps<{}>) {
	const router = useRouter();
	const [isOpenNewsletterForm, setIsOpenNewsLetterForm] = useState(false);

	const handleNewsleterClick = (ev: React.MouseEvent) => {
		const beacon: AnalyticsModel = {
			eventType: 'element',
			origin: window.location.href,
			element: {
				id: ev.currentTarget.id,
				title: ev.currentTarget.getAttribute('title'),
			},
		};

		Analytics.sendBeacon(
			process.env.NEXT_PUBLIC_API_DOMAIN + '/analytics',
			beacon
		);

		setIsOpenNewsLetterForm(!isOpenNewsletterForm);
	};

	return (
		<div className="bg-primary-dark relative z-10">
			<div className="h-12 w-full">
				<Container className="font-bold h-full flex py-2 items-center justify-between gap-x-8" fullWidth>
					{/* <button
						id="boletim-informativo-topbar"
						title="Inscreva-se em nosso boletim informativo"
						className="button bg-white text-black hover:text-white w-max uppercase h-[32px] rounded cursor-pointer text-xs [line-height:1]"
						onClick={handleNewsleterClick}
					>
						Inscreva-se em nosso boletim informativo!
					</button> */}
					<NextLink
						id="topbar-cartao-doador"
						title="Faça seu cartão doador!"
						target='_blank'
						onClick={handleNewsleterClick}
						href="/cartao-doador"
						className="button bg-white text-black hover:text-white w-max uppercase h-[32px] rounded cursor-pointer text-xs [line-height:1] text-center"
					>
						Faça seu cartão doador!
					</NextLink>
				</Container>
			</div>

			<Header />
			<main className="bg-gray-50 relative min-h-[calc(100vh-6rem)] landscape:min-h-[calc(100vh-6rem)] w-full">
				{props.children}
			</main>
			<Footer />

			<div id="off-canvas">
				{/* <DonorSupportButton /> */}
				<SocialLinks />
				{/* <Accessibility /> */}
			</div>

			{isOpenNewsletterForm && (
				<ModalBoletimInformativo
					isOpen={isOpenNewsletterForm}
					setIsOpen={setIsOpenNewsLetterForm}
				/>
			)}
		</div>
	);
}
