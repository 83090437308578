import { ExtendProps } from 'app';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export default function Portal(props: ExtendProps<{}>) {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);

		return () => setMounted(false);
	}, []);

	return mounted
		? createPortal(props.children, document.querySelector('#portal'))
		: null;
}