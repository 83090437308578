export interface AnalyticsPage {
	eventType: 'page';
	page: {
		origin: string;
		title: string;
	};
}

export interface AnalyticsElement {
	eventType: 'element';
	origin: string;
	element?: {
		id: string;
		title?: string;
	};
}

export type AnalyticsModel = AnalyticsPage | AnalyticsElement;

export class Analytics {
	static sendBeacon(href: string, beacon: AnalyticsModel) {
		navigator.sendBeacon(href, JSON.stringify(beacon));
	}
}
