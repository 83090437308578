import NextLink from 'next/link';
import NavigationSocialLink from '../NavigationSocialLink';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import NextImage from 'next/image';
import { useAccessibilityStore } from '@/contexts/accessibility';
import { SlSocialSpotify } from 'react-icons/sl';
import { BsSpotify, BsYoutube } from 'react-icons/bs';

type SocialNavigationProps = {};
export default function SocialLinks(props: SocialNavigationProps) {
	const accessibilityStore = useAccessibilityStore();

	return (
		<div
			className={`z-[9999] fixed top-2/3 -translate-y-72 left-0 hidden lg:flex flex-col ${
				accessibilityStore.enabled ? 'grayscale' : ''
			}`}
		>
			<NavigationSocialLink
				background={'#FF0000'}
				href={'https://www.youtube.com/@deixevivo'}
				icon={<BsYoutube />}
				aria-label="Link para o perfil do Instituto Deixe Vivo no YouTube"
			/>
			<NavigationSocialLink
				background={'#1ED760'}
				href={'https://open.spotify.com/show/38absqTWyntQNAqSfxgq4l?si=3cf2d02bfdd3468b'}
				icon={<BsSpotify />}
				aria-label="Link para o perfil do Instituto Deixe Vivo no Spotify"
			/>
			<NavigationSocialLink
				background={
					'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)'
				}
				href={'https://www.instagram.com/deixevivo/'}
				icon={<InstagramIcon />}
				aria-label="Link para o perfil do Instituto Deixe Vivo no Instagram"
			/>
			<NavigationSocialLink
				background={'#0a66c2'}
				href={'https://www.linkedin.com/company/deixe-vivo/'}
				icon={<LinkedInIcon />}
				aria-label="Link para o perfil do Instituto Deixe Vivo no LinkedIn"
			/>
			<NavigationSocialLink
				background={'#000000'}
				href={'https://www.threads.net/@deixevivo'}
				icon={
					<NextImage
						src="/assets/images/logos/threads-app-icon.svg"
						alt="Threads"
						height={18}
						width={18}
					/>
				}
				aria-label="Link para o perfil do Instituto Deixe Vivo no Threads"
			/>
			<NavigationSocialLink
				background={'#0e8cf1'}
				href={'https://www.facebook.com/instituto.deixevivo/'}
				icon={<FacebookIcon />}
				aria-label="Link para o perfil do Instituto Deixe Vivo no Facebook"
			/>
		</div>
	);
}
