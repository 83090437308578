import NextLink, { LinkProps } from 'next/link';
import { useAccessibilityStore } from '@/contexts/accessibility';
import { twMerge } from 'tailwind-merge'


export default function DonorButton({
	className,
}: Partial<LinkProps> & {
	className?: Partial<React.ComponentProps<'p'>['className']>;
}) {
	const accessibilityStore = useAccessibilityStore();
	const pallet = accessibilityStore.getPallet();

	const classNames = twMerge('button text-xs rounded-lg text-uppercase bg-secondary-dark hover:[background-color:#ED5D5D!important]', className)

	return (
		<NextLink className={classNames} href="/apoio" tabIndex={0}>
			<strong aria-label="Link da página de apoio às nossas atividades sobre a doação de órgãos">
				quero apoiar!
			</strong>
		</NextLink>
	);
}
