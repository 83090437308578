import { ExtendProps } from 'app';
import {
	useEffect,
	useRef,
	useState,
	Dispatch,
	SetStateAction,
	useCallback,
} from 'react';

import CloseIcon from '@mui/icons-material/Close';

type ModalProps = ExtendProps<{
	title?: string;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}>;
export default function Modal(props: ModalProps) {
	const frameRef = useRef<HTMLIFrameElement>();
	const { isOpen, setIsOpen } = props;

	const dialogRef = useRef<HTMLDialogElement>(null);
	const backdropRef = useRef<HTMLDivElement>();
	// const dialogContentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		dialogRef.current.addEventListener('keydown', (ev) => {
			if (ev.key === 'Escape') {
				document.body.style.overflow = 'auto'
				setIsOpen(false);
			}
		});
	}, [setIsOpen]);

	// useEffect(() => {
	// 	backdropRef.current.addEventListener('click', ev => setIsOpen(false))
	// }, [])

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';

			if (!dialogRef.current.open) {
				dialogRef.current.showModal();
			}
		} else {
			document.body.style.overflow = 'auto';
			dialogRef.current.close();
		}

		// return () => {
		// 	if (dialogRef.current) document.body.style.overflow = 'hidden';
		// 	else document.body.style.overflow = 'unset';
		// };
	}, [isOpen]);

	// useEffect(() => {
	// 	dialogContentRef.current.addEventListener('focusout', () => {
	// 		document.body.style.overflow = 'unset';
	// 		dialogRef.current.close();
	// 		setIsOpen(false);
	// 	});
	// }, []);

	return (
		<div
			tabIndex={0}
			className={`modal-backdrop select-none w-screen h-screen fixed top-0 left-0 z-[3000] bg-[#00000060] ${
				!isOpen ? 'hidden z-[0]' : 'block z-[3000]'
			}`}
		>
			<div
				ref={backdropRef}
				className="modal-wrapper relative flex items-center justify-center h-full"
			>
				<dialog
					className="relative w-[90%] md:w-[70%] lg:w-[30%] p-6 min-h-[30%] top-50 ytranslate-y-1/2 overflow-hidden bg-white text-black shadow-xl rounded-lg"
					ref={dialogRef}
				>
					{/* title */}
					<div className="relative flex items-center justify-between min-h-[5rem]">
						<span className="font-bold">{props.title}</span>
						<button
							aria-label="Fechar caixa de diálogo"
							className="hover:bg-white hover:text-black flex items-center justify-center rounded-sm p-1 ml-4"
							onClick={(_) => {
								document.body.style.overflowY = 'auto';
								setIsOpen(false)
							}}
						>
							<CloseIcon className="text-xl" />
						</button>
					</div>

					{/* Content */}
					<div className="relative h-[100%] overflow-y-hidden ">
						{props.children}
					</div>
				</dialog>
			</div>
		</div>
	);
}
