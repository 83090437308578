import Alert, { AlertProps } from '../Alert';
import Modal from '../Modal';
import Portal from '../Portal';
import { useState } from 'react';
import ModalDocuments from '@/components/ModalDocuments';

interface Props {
	isOpen?: boolean;
	setIsOpen?: (state: boolean) => void;
}
export const ModalBoletimInformativo = ({ isOpen, setIsOpen }: Props) => {
	const [alertState, setAlertState] = useState({} as AlertProps);
	const [isOpenTermos, setIsOpenTermos] = useState(false);

	const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		event.currentTarget.checkValidity();

		let formData = new FormData(event.currentTarget);
		let data: Partial<{
			fullName: string;
			email: string;
			newsletter: string | boolean;
			terms_policy: string | boolean;
		}> = {};

		for (let entry of formData.entries()) {
			data = {
				...data,
				[entry[0]]: entry[1],
			};
		}

		if (data.newsletter == 'on') {
			data.newsletter = true;
		}

		if (data.terms_policy == 'on') {
			data.terms_policy = true;
		}

		const response = await fetch('/api/v1/public/newsletter', {
			method: 'POST',
			body: JSON.stringify(data),
			mode: 'no-cors',
		});

		if (response) {
			const result = await response.json();
			setAlertState((prev) => ({
				isOpen: true,
				message: result.body.message,
				secondsToClose: 5000,
			}));

			setTimeout(() => {
				setAlertState((prev) => ({
					isOpen: false,
					message: '',
					secondsToClose: 5000,
				}));
			}, 5000);

			setIsOpen(false);
		}
	};

	return (
		<>
			<Portal>
				<Modal
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					title="Inscreva-se em nosso Boletim Informativo e fique por dentro de tudo o que acontece no Instituto Deixe Vivo!"
				>
					<form id="form-newsletter" onSubmit={handleFormSubmit}>
						<label className="block" htmlFor="nome">
							<span className="block">Nome Completo:</span>
							<input
								className="w-full h-8 p-2"
								id="nome"
								name="fullName"
								type="text"
								required
								minLength={20}
								maxLength={40}
							/>
						</label>
						<label className="mt-2 block" htmlFor="email">
							<span className="block">Email:</span>
							<input
								className="w-full h-8 p-2"
								id="email"
								name="email"
								type="email"
								required
								minLength={20}
								maxLength={40}
							/>
						</label>

						<fieldset className="border-2 p-4">
							<legend>
								<span>Aceites</span>
							</legend>

							<div>
								<input
									required
									id="terms_policy"
									type="checkbox"
									name="terms_policy"
								/>
								<label htmlFor="terms_policy" className="ml-2">
									Aceito os{' '}
									<span
										className="text-blue-500 cursor-pointer"
										onClick={(ev) => setIsOpenTermos(true)}
									>
										termos de serviços e política de privacidade.
									</span>
								</label>
							</div>

							<div>
								<input
									required
									id="newsletter"
									type="checkbox"
									name="newsletter"
								/>
								<label htmlFor="newsletter" className="ml-2">
									Aceito receber emails sobre as ações e novidades do Instituto.
								</label>
							</div>
						</fieldset>

						<button
							type="submit"
							form="form-newsletter"
							className="button bg-secondary-dark rounded mt-4 w-24 uppercase font-bold"
						>
							Inscrever
						</button>
					</form>

					{isOpenTermos && (
						<ModalDocuments
							isOpen={isOpenTermos}
							setIsOpen={setIsOpenTermos}
							src="/assets/docs/politica-privacidade-e-termos-de-servicos.pdf"
							title="Política de Privacidade e Termos de Serviços"
						/>
					)}
				</Modal>
			</Portal>

			<Portal>
				<Alert {...alertState} />
			</Portal>
		</>
	);
};
