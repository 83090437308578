import DonorButton from '@/components/DonorButton';
import Logo from '@/components/Logo';
import NavigationItem from '@/components/NavigationItem';
import NavigationItemLabel from '@/components/NavigationItemLabel';
import NavigationNestedItem from '@/components/NavigationNestedItem';
import NavigationNestedList from '@/components/NavigationNestedList';
import Container from '@/components/Container';
import { NavigationItemHoverProvider } from '@/contexts/NavigationItemHoverContext';

import { useEffect, useRef, useState } from 'react';
import NextLink from 'next/link';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export default function Header() {
	const navItemRef1 = useRef<HTMLUListElement>(null);
	const navItemRef2 = useRef<HTMLUListElement>(null);
	const navItemRef3 = useRef<HTMLUListElement>(null);
	const navItemRef4 = useRef<HTMLUListElement>(null);
	const navItemRef5 = useRef<HTMLUListElement>(null);
	const [menuIsOpened, toggleMenuIsOpened] = useState(false);
	const [isScrolledOutTopBar, setIsScrolledOutTopBar] = useState(false);

	useEffect(() => {
		// prettier-ignore
		menuIsOpened
			? (
				// (document.body.style.overflowY = 'hidden'),
			  (document.querySelector('main').style.filter = 'brightness(0.5)')
			)
			: (
				// (document.body.style.overflowY = 'unset'),
				// (document.body.style.filter = 'brightness(1)'),
				(document.querySelector('main').style.filter = 'brightness(1)')
			);
	}, [menuIsOpened]);

	useEffect(() => {
		const main = document.querySelector('main');

		document.onscroll = () => {
			toggleMenuIsOpened(false);
		};

		['click', 'focusin'].forEach((event, k) =>
			main.addEventListener(event, () => {
				toggleMenuIsOpened(false);
			})
		);
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', (ev) => {
			const scrollY = window.scrollY;
			if (scrollY >= 80) {
				setIsScrolledOutTopBar(true);
			} else {
				setIsScrolledOutTopBar(false);
			}
		});
	}, [isScrolledOutTopBar]);

	return (
		<div className="header-wrapper">
			<Container>
				<header className="header">
					<div className="header-brand">
						<Logo />
						<DonorButton />
					</div>

					<nav
						className="header-navigation"
						aria-label="Menu de navegação"
						accessKey="m"
					>
						<ul className="header-navigation-menu">
							<NavigationItemHoverProvider ref={navItemRef1}>
								<NavigationItem>
									<NavigationItemLabel tabIndex={0}>
										Sobre Nós
									</NavigationItemLabel>
									<NavigationNestedList tooltip={{ direction: 'right' }}>
										<NavigationNestedItem>
											<NextLink
												aria-labelledby="#nossa-historia"
												href="/sobre-o-instituto/nossa-historia"
												tabIndex={0}
											>
												Conheça o Instituto
											</NextLink>
										</NavigationNestedItem>
										<NavigationNestedItem>
											<NextLink
												href="/sobre-o-instituto/por-que-existimos"
												tabIndex={0}
											>
												Por que existimos?
											</NextLink>
										</NavigationNestedItem>
										<NavigationNestedItem>
											<NextLink
												href="/sobre-o-instituto/quem-faz-acontecer"
												tabIndex={0}
											>
												Quem faz acontecer
											</NextLink>
										</NavigationNestedItem>
										{/* <NavigationNestedItem>
											<NextLink
												href="/sobre-o-instituto/boletim-informativo"
												tabIndex={0}
											>
												Boletim Informativo
											</NextLink>
										</NavigationNestedItem> */}
										<NavigationNestedItem>
											<NextLink href="/cartao-doador" tabIndex={0}>
												Cartão Doador
											</NextLink>
										</NavigationNestedItem>
										<NavigationNestedItem>
											<NextLink
												href="/sobre-o-instituto/transparencia"
												tabIndex={0}
											>
												Transparência
											</NextLink>
										</NavigationNestedItem>
									</NavigationNestedList>
								</NavigationItem>
							</NavigationItemHoverProvider>
							<NavigationItemHoverProvider ref={navItemRef2}>
								<NavigationItem>
									<NavigationItemLabel tabIndex={0}>
										O que fazemos
									</NavigationItemLabel>
									<NavigationNestedList tooltip={{ direction: 'right' }}>
										<NavigationNestedItem>
											<NextLink
												className="flex"
												href="/o-que-fazemos/educar-para-doar"
												tabIndex={0}
											>
												{/* Educar para Doar: doação de órgãos */}
												Informar, Inspirar e conscientizar: doação de órgãos
											</NextLink>
										</NavigationNestedItem>
										<NavigationNestedItem>
											<NextLink
												className="flex"
												href="/o-que-fazemos/saude-renal"
												tabIndex={0}
											>
												Saúde Renal: cuidado e prevenção
											</NextLink>
										</NavigationNestedItem>

										<NavigationNestedItem>
											<NextLink
												className="flex"
												href="/o-que-fazemos/rede-de-acolhimento"
												tabIndex={0}
											>
												Rede de Acolhimento: pacientes e cuidadores
											</NextLink>
										</NavigationNestedItem>
										{/* <NavigationNestedItem>
											<NextLink
												className="flex"
												href="/o-que-fazemos/deixe-vivo-por-ai"
												tabIndex={0}
											>
												Deixe Vivo Por Aí: a doação de órgãos em todos os
												lugares
											</NextLink>
										</NavigationNestedItem> */}
									</NavigationNestedList>
								</NavigationItem>
							</NavigationItemHoverProvider>
							<NavigationItemHoverProvider ref={navItemRef2}>
								<NavigationItem>
									<NavigationItemLabel tabIndex={0}>
										Podcast
									</NavigationItemLabel>
									<NavigationNestedList tooltip={{ direction: 'right' }}>
										<NavigationNestedItem>
											<NextLink
												className="flex"
												href="https://www.youtube.com/@deixevivo"
												target='_blank'
												tabIndex={0}
											>
												{/* Educar para Doar: doação de órgãos */}
												YouTube
											</NextLink>
										</NavigationNestedItem>
										<NavigationNestedItem>
											<NextLink
												className="flex"
												href="https://open.spotify.com/show/38absqTWyntQNAqSfxgq4l?si=3cf2d02bfdd3468b"
												target='_blank'
												tabIndex={0}
											>
												Spotify
											</NextLink>
										</NavigationNestedItem>
									</NavigationNestedList>
								</NavigationItem>
							</NavigationItemHoverProvider>
							{/* <NavigationItemHoverProvider ref={navItemRef3}>
								<NavigationItem>
									<NavigationItemLabel>
										<NextLink
											href="/quem-apoia"
											className="w-full h-full flex items-center"
											aria-label="Veja quem nos apoia"
										>
											Quem Apoia
										</NextLink>
									</NavigationItemLabel>
								</NavigationItem>
							</NavigationItemHoverProvider> */}
							<NavigationItemHoverProvider ref={navItemRef4}>
								<NavigationItem>
									<NavigationItemLabel>
										<NextLink
											href="https://blog.deixevivo.org.br"
											className="w-full h-full flex items-center"
											aria-label="Acesse nossa blog"
											target="_blank"
										>
											Blog
										</NextLink>
									</NavigationItemLabel>
								</NavigationItem>
							</NavigationItemHoverProvider>
							<NavigationItemHoverProvider ref={navItemRef5}>
								<NavigationItem>
									<NavigationItemLabel>
										<NextLink
											href="https://reserva.ink/deixevivo"
											className="w-full h-full flex items-center"
											aria-label="Acesse nossa loja virtual"
											target="_blank"
										>
											Loja Virtual
										</NextLink>
									</NavigationItemLabel>
								</NavigationItem>
							</NavigationItemHoverProvider>
						</ul>
					</nav>

					<div className="block lg:hidden">
						<button
							onClick={() => toggleMenuIsOpened((prev) => !prev)}
							className="button shadow-sm rounded-md"
							aria-label={
								!menuIsOpened
									? 'Abrir menu de navegação'
									: 'fechar menu de navegação'
							}
						>
							{!menuIsOpened ? (
								<MenuIcon className="text-sm" />
							) : (
								<CloseIcon className="text-sm animate-[spin_650ms_ease-in-out_1]" />
							)}
						</button>

						<div
							style={{ top: !isScrolledOutTopBar ? '3rem' : '4rem' }}
							className={`z-50 bg-white fixed left-0 w-[calc(100vw-5rem)] sm:w-[calc(100vw-7rem)] md:w-[calc(100vw-14rem)] h-screen shadow-xl transform transition-[transform] duration-500 translate-x-[-100vw] ${
								menuIsOpened
									? 'translate-x-[0px] visible'
									: 'translate-x-[-100vw] invisible'
							}`}
						>
							{/* <div className="header-brand container">
							<Logo />
							<DonorButton />
						</div> */}

							<ul className="bg-primary-dark flex flex-col h-full px-4 py-4 ">
								<MobileMenu />
							</ul>
						</div>
					</div>
				</header>
			</Container>
		</div>
	);
}

function MobileMenu() {
	return (
		<div>
			<details className="[open:padding:5rem] py-2 border-b-2">
				<summary className="cursor-pointer list-none text-white">
					<strong className="text-white font-bold">Sobre o Instituto</strong>
				</summary>
				<ul className="text-white py-2">
					<li className="border-b-2 border-white py-2">
						<NextLink href="/sobre-o-instituto/nossa-historia">
							Conheça o Instituto
						</NextLink>
					</li>
					<li className="border-b-2 border-white py-2">
						<NextLink href="/sobre-o-instituto/por-que-existimos">
							Por que existimos?
						</NextLink>
					</li>
					<li className="border-b-2 border-white py-2">
						<NextLink href="/sobre-o-instituto/quem-faz-acontecer">
							Quem faz acontecer
						</NextLink>
					</li>
					<li className="border-b-2 border-white py-2">
						<NextLink href="/cartao-doador">Cartão Doador</NextLink>
					</li>
					<li className="border-b-2 border-white py-2">
						<NextLink href="/sobre-o-instituto/transparencia">
							Transparência
						</NextLink>
					</li>
				</ul>
			</details>

			<details className="[open:padding:5rem] py-2 border-b-2">
				<summary className="cursor-pointer list-none text-white">
					<strong className="text-white font-bold">O que fazemos</strong>
				</summary>
				<ul className="text-white py-2">
					<li className="py-2 border-b-2 border-white">
						<NextLink
							className="flex"
							href="/o-que-fazemos/educar-para-doar"
							tabIndex={0}
						>
							{/* Educar para Doar: doação de órgãos */}
							Informar, Inspirar e conscientizar: doação de órgãos
						</NextLink>
					</li>
					<li className="py-2 border-b-2 border-white">
						<NextLink
							className="flex py-1"
							href="/o-que-fazemos/saude-renal"
							tabIndex={0}
						>
							Saúde Renal: cuidado e prevenção
						</NextLink>
					</li>
					<li className="py-2 border-b-2 border-white">
						<NextLink
							className="flex py-1"
							href="/o-que-fazemos/rede-de-acolhimento"
							tabIndex={0}
						>
							Rede de Acolhimento: pacientes e cuidadores
						</NextLink>
					</li>
					{/* <li className="py-2">
						<NextLink
							className="flex py-1"
							href="/o-que-fazemos/deixe-vivo-por-ai"
							tabIndex={0}
						>
							Deixe Vivo Por Aí: a doação de órgãos em todos os lugares
						</NextLink>
					</li> */}
				</ul>
			</details>

			<details className="[open:padding:5rem] py-2 border-b-2">
				<summary className="cursor-pointer list-none text-white">
					<strong className="text-white font-bold">Podcast</strong>
				</summary>
				<ul className="text-white py-2">
					<li className="py-2 border-b-2 border-white">
						<NextLink
							className="flex"
							href="https://www.youtube.com/@deixevivo"
							target='_blank'
							tabIndex={0}
						>
							{/* Educar para Doar: doação de órgãos */}
							YouTube
						</NextLink>
					</li>
					<li className="py-2 border-b-2 border-white">
						<NextLink
							className="flex py-1"
							href="https://open.spotify.com/show/38absqTWyntQNAqSfxgq4l?si=3cf2d02bfdd3468b"
							target='_blank'
							tabIndex={0}
						>
							Spotify
						</NextLink>
					</li>
				</ul>
			</details>

			{/* <NextLink
				href="/quem-apoia"
				className="accordion-title w-full flex items-center justify-between py-2 text-white border-b-2"
				aria-label="Veja quem nos apoia"
				tabIndex={0}
			>
				<span className="font-bold">Quem Apoia</span>
			</NextLink> */}

			<NextLink
				href="https://blog.deixevivo.org.br"
				target="_blank"
				className="accordion-title w-full flex items-center justify-between py-2 text-white border-b-2"
				aria-label="Acesse nosso blog"
				tabIndex={0}
			>
				<span className="font-bold">Blog</span>
			</NextLink>

			<NextLink
				href="https://reserva.ink/deixevivo"
				target="_blank"
				className="accordion-title w-full flex items-center justify-between py-2 text-white border-b-2"
				aria-label="Acesse nossa loja virtual"
				tabIndex={0}
			>
				<span className="font-bold">Loja Virtual</span>
			</NextLink>
		</div>
	);
}
