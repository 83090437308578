import { useAccessibilityStore } from '@/contexts/accessibility';
import NextImage from 'next/image';
import NextLink from 'next/link';

export default function Logo() {
	const accessibilityStore = useAccessibilityStore();

	return (
		<NextLink href="/" tabIndex={0} className='h-[64px] flex items-center overflow-hidden'>
			<figure>
				<picture className="brand">
					<source
						srcSet="/assets/images/logos/logo.png"
						media="(min-width: 0px)"
					/>
					<NextImage
						unoptimized
						aria-label="Logo do Instituto, ao lado esquerdo da imagem um rosto feliz e ao lado direito texto que diz Instituto Deixe Vivo"
						className="w-[144px] lg:w-[144px] h-[auto]"
						alt="Logo do Instituto, ao lado esquerdo da imagem um rosto feliz e ao lado direito texto que diz Instituto Deixe Vivo"
						src="/assets/images/logos/logo.png"
						title="Logo do Instituto Deixe Vivo"
						// width={144}
						// height={64}
						width={500}
						height={300}
					/>
				</picture>
				<figcaption className='hidden'>
					Logo do Instituto, ao lado esquerdo da imagem um rosto feliz e ao lado
					direito texto que diz Instituto Deixe Vivo
				</figcaption>
			</figure>
		</NextLink>
	);
}
