import { ExtendProps } from 'app';
import { HTMLAttributes } from 'react';

export default function Container(
	props: ExtendProps<HTMLAttributes<HTMLDivElement> & { fullWidth?: boolean }>
) {
	const { className, fullWidth, children } = props;

	return (
		<div className={`container px-4 mx-auto relative md:px-8 lg:px-16 ${fullWidth ? 'w-full' : ''} ${className}`}>
			{children}
		</div>
	);
}
