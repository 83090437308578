import { useAccessibilityStore } from '@/contexts/accessibility';
import { NavigationItemHoverContext } from '@/contexts/NavigationItemHoverContext';
import { ExtendProps } from 'app';
import { HTMLAttributes, MouseEvent, useContext } from 'react';

export default function NavigationNestedList(
	props: ExtendProps<
		{
			tooltip: { direction: 'left' | 'right' };
		} & HTMLAttributes<HTMLUListElement>
	>
) {
	const { isHovered, setIsHovered } = useContext(NavigationItemHoverContext);
	const handleHover = (event: MouseEvent<HTMLElement>, value: boolean) =>
		setIsHovered(value);

	return (
		<ul
			onMouseEnter={(ev) => handleHover(ev, true)}
			onMouseLeave={(ev) => handleHover(ev, false)}
			className={`absolute box-border cursor-pointer bg-primary-dark top-16 xl:top-24 right-0 flex flex-col h-0 gap-y-4 rounded-b-lg w-max overflow-y-hidden group-hover:flex group-hover:h-max group-hover:px-6 group-hover:py-6 group-hover:transition-h group-hover:duration-200 ${
				props.tooltip.direction ? props.tooltip.direction + '-0' : 'left-0'
			}`}
		>
			{props.children}
		</ul>
	);
}

/* 
group-focus-within:flex group-focus-within:h-max group-focus-within:px-6 group-focus-within:py-6 group-focus-within:transition-h group-focus-within:duration-200
*/
