import { ExtendProps } from 'app';
import {
	createContext,
	HTMLAttributes,
  Dispatch,
	ForwardedRef,
	forwardRef,
	SetStateAction,
	useState,
} from 'react';

export type NavigationItemHoverContextProps = ExtendProps<
	{
		isHovered?: boolean;
		setIsHovered?: Dispatch<SetStateAction<boolean>>;
		ref: ForwardedRef<HTMLElement>;
	} & HTMLAttributes<HTMLElement>
>;
export const NavigationItemHoverContext = createContext({
	isHovered: false,
} as NavigationItemHoverContextProps);

export const NavigationItemHoverProvider = forwardRef<
	HTMLElement,
	Omit<NavigationItemHoverContextProps, 'setIsHovered'>
>(({ isHovered, children }, ref) => {
	const [_isHovered, _setIsHovered] = useState(() => {
		if (isHovered) return isHovered;
		return false;
	});

	return (
		<NavigationItemHoverContext.Provider
			value={{ ref, isHovered: _isHovered, setIsHovered: _setIsHovered }}
		>
			{children}
		</NavigationItemHoverContext.Provider>
	);
});
NavigationItemHoverProvider.displayName = 'NavigationItemHoverProvider';
