import { ExtendProps } from 'app';
import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';

export type AlertProps = {
	message: string;
	isOpen: boolean;
	secondsToClose: number;
};
export default function Alert(props: ExtendProps<AlertProps>) {
	const [isOpen, setIsOpen] = useState(() => props.isOpen);

	useEffect(() => {
		setIsOpen(props.isOpen);
	}, [props.isOpen]);

	useEffect(() => {
		setTimeout(() => {
			setIsOpen(false);
		}, props.secondsToClose);
	}, [props.secondsToClose]);

	return (
		<div
			data-none=""
			className="fixed bottom-4 right-4 h-[80px] min-w-[300px] p-4 z-[4000] bg-secondary-dark shadow-lg rounded"
			style={{ display: isOpen ? 'block' : 'none' }}
		>
			<div className="flex">
				<div className="w-[95%]">
					<div className="text-white text-sm font-bold">{props.message}</div>
					<div className="w-[100%] h-2 border-b-4 border-black absolute left-0 bottom-0 transform"></div>
				</div>
				<div
					className="flex justify-end text-white cursor-pointer"
					onClick={(ev) => setIsOpen(false)}
				>
					<MdClose />
				</div>
			</div>
		</div>
	);
}
