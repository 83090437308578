import { ExtendProps } from 'app';

import { HTMLAttributes } from 'react';

export default function NavigationItem(props: ExtendProps<HTMLAttributes<HTMLLIElement>>) {
	return (
		<li className="group flex flex-col h-full items-center relative min-w-max">
			{props.children}
		</li>
	);
}