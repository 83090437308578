import Container from '../Container';
import NextImage from 'next/image';
import NextLink from 'next/link';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { useAccessibilityStore } from '@/contexts/accessibility';

export default function Footer() {
	const accessibilityStore = useAccessibilityStore();
	const pallet = accessibilityStore.getPallet();
	const primaryBgColor = `bg-primary-dark`;

	return (
		<div className={primaryBgColor}>
			<footer tabIndex={0} aria-label="Rodapé">
				<Container className="flex flex-col sm:flex-row flex-wrap gap-8 py-8">
					<div className="w-max">
						<strong className="text-xl text-white">#institutodeixevivo</strong>
						<ul
							className="mt-4 flex flex-col gap-y-2 text-white"
							aria-label="Links rápidos do nosso site"
							tabIndex={0}
							role="list"
						>
							<li role="listitem">
								<NextLink href="/sobre-o-instituto/por-que-existimos">
									Por que existimos?
								</NextLink>
							</li>
							<li role="listitem">
								<NextLink href="/sobre-o-instituto/nossa-historia">
									Conheça o Instituto
								</NextLink>
							</li>
							<li role="listitem">
								<NextLink
									aria-label="Fazer seu cartão doador"
									href="/cartao-doador"
								>
									Cartão Doador
								</NextLink>
							</li>
							<li role="listitem">
								<NextLink
									target="_blank"
									href="https://www.atados.com.br/ong/instituto-deixe-vivo/vagas"
								>
									Vagas para voluntários
								</NextLink>
							</li>
						</ul>
					</div>

					<div className="w-max">
						<strong className="text-xl text-white">Redes Sociais</strong>
						<ul
							className="mt-4 flex flex-col gap-y-2 text-white"
							aria-label="Links das nossas redes sociais"
							tabIndex={0}
							role="list"
						>
							<li role="listitem">
								<NextLink
									href={'https://www.instagram.com/deixevivo/'}
									className="flex items-center gap-x-2"
									aria-label="Ir para a página do Instagram do Instituto Deixe Vivo"
								>
									<InstagramIcon />
									<span className="">Instagram</span>
								</NextLink>
							</li>
							<li role="listitem">
								<NextLink
									href={'https://www.linkedin.com/company/deixe-vivo/'}
									className="flex items-center gap-x-2"
									aria-label="Ir para a página do LinkedIn do Instituto Deixe Vivo"
								>
									<LinkedInIcon />
									<span className="">LinkedIn</span>
								</NextLink>
							</li>
							<li role="listitem">
								<NextLink
									href={'https://www.threads.net/@deixevivo'}
									className="flex items-center gap-x-2"
									aria-label="Ir para a página do Threads do Instituto Deixe Vivo"
								>
									<div className="w-[24px] h-[24px]">
										<NextImage
											className="mx-auto"
											src="/assets/images/logos/threads-app-icon.svg"
											alt="Threads"
											height={18}
											width={18}
										/>
									</div>
									<span className="">Threads</span>
								</NextLink>
							</li>
							<li role="listitem">
								<NextLink
									href={'https://www.facebook.com/instituto.deixevivo/'}
									className="flex items-center gap-x-2"
									aria-label="Ir para a página do Facebook do Instituto Deixe Vivo"
								>
									<FacebookIcon />
									<span className="">Facebook</span>
								</NextLink>
							</li>
						</ul>
					</div>

					<div className="w-max">
						<strong className="text-xl text-white">Fale Conosco</strong>
						<address>
							<ul
								className="mt-4 flex flex-col gap-y-2 text-white"
								tabIndex={0}
								role="list"
								aria-label="Nossos contatos"
							>
								<li role="listitem">
									<span
										tabIndex={0}
										aria-label="Atendimento de segunda a sexta-feira, das 10 às 17h"
										className="text-sm block font-normal"
									>
										Atendimento de seg. a sex. das 10h as 17h
									</span>
								</li>
								<li role="listitem">
									<NextLink
										aria-label="Enviar e-mail para contato@deixevivo.org.br"
										href="mailto:contato@deixevivo.org.br"
									>
										contato@deixevivo.org.br
									</NextLink>
								</li>
								{/* <li role="listitem">
									<NextLink
										className="flex items-center"
										aria-label="Entrar em contato via WhatsApp com o Instituto Deixe Vivo"
										href="mailto:contato@deixevivo.org.br"
									>
										<WhatsappIcon className="text-xl" />
										<span>&nbsp;+55 (11) 99999-9999</span>
									</NextLink>
								</li> */}
								<li className="text-sm w-max whitespace-pre-wrap">
									<span>Endereço para correspondências:</span>
									<br />
									<p className="w-[300px]">
										Av. Paulista, nº 1636, Cj. 4, Pav. 15, Bela Vista, CEP
										01310-200, São Paulo-SP.
									</p>
								</li>
								<li role="listitem">
									<span className="text-sm">CNPJ: 46.033.424/0001-53</span>
								</li>
							</ul>
						</address>
					</div>
				</Container>

				<div className="py-4 bg-black text-white text-xs font-bold">
					<Container className="flex items-center justify-between">
						<p className="flex flex-col sm:flex-row gap-x-2">
							Copyright &copy; Instituto Deixe Vivo
							{/* <span className="hidden sm:block">-</span> */}
						</p>
						<span>Desenvolvido por OliveiraDev</span>
					</Container>
				</div>
			</footer>
		</div>
	);
}
